import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Animate from 'rc-animate'

import { useAppSelector } from '_src/store/hooks'

import { navs } from './navs'

import './index.less'
import { isDev } from '../../utils'

const PassMenu = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { activeUrl } = useAppSelector((store) => store.menu)
  const [visible, setVisible] = useState(false)
  const [show, setShow] = useState(0)
  const openUrl = isDev() ? 'http://localhost:3001' : 'https://box.cccn.pro'
  const goLink = (item, type = 'pc') => {
    if (Object.prototype.hasOwnProperty.call(item, 'children')) return
    const { url = '' } = item
    type == 'mobile' && onClick()
    // 创建需要权限
    if (url && url == '/issued') {
      window.open('https://ex.cccn.pro', '_blank')
    } else if (url && url == '/protect') {
      window.open(`${openUrl}${url}`, '_blank')
    } else {
      url && history.push(url)
      type = 'pc' && setShow(-1)
    }
  }
  const onClick = () => {
    setVisible(!visible)
  }
  const showMenuItem = (index) => {
    setShow(index)
  }
  useEffect(() => {}, [])
  return (
    <Fragment>
      {/*  */}
      <div className="pc-menu-container">
        {navs(t).map((item, index) => (
          <div
            key={index}
            onClick={() => {
              goLink(item)
            }}
            onMouseEnter={() => {
              showMenuItem(index)
            }}
            onMouseLeave={() => {
              setShow(-1)
            }}
            className={classNames('pc-menu-item', {
              'inft-menu-active': activeUrl.includes(item.url),
            })}
          >
            <div className="item-title">{item.label}</div>
            {item?.children && (
              <Animate transitionName="inft-fade" transitionAppear>
                {show == index && (
                  <div className="menu-item-container">
                    {item.children.map((it, ix) => (
                      <div
                        onClick={() => {
                          goLink(it)
                        }}
                        className={classNames('menu-item-content', {
                          'inft-menu-active': activeUrl.includes(it.url),
                        })}
                        key={ix}
                      >
                        {it.label}
                      </div>
                    ))}
                  </div>
                )}
              </Animate>
            )}
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default PassMenu
