import React from 'react'
import classNames from 'classnames'
import './index.less'
import { Anchor } from 'antd'
import { imgUrl } from '../../utils/staticUrl'
import PassMenu from '../Menu'
const backTop = [
  {
    key: 'backTop',
    href: '#backTop',
    title: (
      <>
        <img src={imgUrl('home/pic_8.png')} />
      </>
    ),
  },
]
const PassHeader = ({ className }) => {
  return (
    <div id={classNames('pass_header', className)}>
      <div className="header_content">
        <div className="header_left">
          <div className="logo"></div>
        </div>
        <div className="header_right">
          <PassMenu />
        </div>
      </div>
      <div className="backTop">
        <Anchor items={backTop} />
      </div>
    </div>
  )
}

export default PassHeader
