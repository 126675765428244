// import React from 'react'
// import Icon from '../Icon'

export const marketUrl = '/'
export const navs = (isMobile = false) => {
  const defaultNavs = [
    {
      label: '首页',
      url: '/home',
      key: '/home',
    },
    {
      label: '核心业务',
      url: '/coreBusiness',
      key: '/coreBusiness',
    },
    {
      label: '版权发行',
      url: '/issued',
      key: '/issued',
    },
    {
      label: '版权保护',
      url: '/protect',
      key: '/protect',
    },
    {
      label: '新闻中心',
      url: '/newsCenter',
      key: '/newsCenter',
    },
    {
      label: '关于我们',
      url: '/about',
      key: '/about',
    },
  ]
  if (isMobile) {
    // const BuyAac = {
    //   label: t('header.Buy_aac'),
    //   url: '/buyAac',
    //   key: '/buyAac',
    // }
    // return [...defaultNavs, BuyAac]
  }

  return defaultNavs
}
