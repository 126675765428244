import React, { useEffect } from 'react'
import { useAppSelector } from '../../store/hooks'
import { imgUrl } from '../../utils/staticUrl'
import './index.less'
const qrCode = [
  {
    img: '',
    title: '版权盒子',
    name: '官方微博',
  },
  {
    img: '',
    title: '版权盒子',
    name: '官方微信工作号',
  },
]
const PassFooter = () => {
  const { lng } = useAppSelector((store) => store.global)
  useEffect(() => {}, [lng])
  return (
    <div id="pass_footer">
      <div className="footer_content">
        <div className="logo">
          <img src={imgUrl('aacCommon/logo1.png')} />
        </div>
        <div className="footer_message">
          <div className="footer_left">
            <div className="left">
              {/* <img src={imgUrl('home/ico_22.png')} alt="" /> */}
              <span>{'国广链（北京）科技有限公司 '}</span>
              {/* <img src={imgUrl('home/ico_23.png')} alt="" /> */}
              <span>{'增值电信许可证 京B2-20222125'}</span>
            </div>
            <div className="left">
              {/* <img src={imgUrl('home/ico_22.png')} alt="" /> */}
              <span>{'京ICP备2022008016号-3'}</span>
            </div>
            <div className="left">
              {/* <img src={imgUrl('home/ico_21.png')} alt="" /> */}
              <span>{'艺术品经营单位备案证明 京门艺(2023) 0017号'}</span>
            </div>
          </div>
          {!!0 && (
            <div className="footer_right">
              {qrCode &&
                qrCode.map((item, index) => (
                  <div className="qrCode" key={index}>
                    <div className="code_left">{item.img && <img src={item.img} alt="" />}</div>
                    <div className="code_right">
                      <p>{item.title}</p>
                      <p>{item.name}</p>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PassFooter
