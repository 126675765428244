const routes = () => [
  // path: '/home/:join?/:invite_code?',携带参数方式
  /**
   * @descrition 携带参数方式
   * @params {String} 当前参数
   * @identification {?} ?为参数可选
   */
  {
    path: '/home',
    component: () => import('../pages/home'),
    exact: true,
  },
  {
    path: '/coreBusiness',
    component: () => import('../pages/coreBusiness'),
    exact: true,
  },
  {
    path: '/newsCenter',
    component: () => import('../pages/newsCenter'),
    exact: true,
  },
  {
    path: '/about',
    component: () => import('../pages/about'),
    exact: true,
  },
]
export default routes
